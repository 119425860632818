import React, { FC, useState, useEffect } from "react";
import {
  CenterCardContainer,
  HighlightedText,
  PageCenter,
} from "../../styles/Global";
import Button from "../ui/Button";
import styled, { keyframes } from "styled-components";
import { device } from "../../styles/BreakPoints";
import { useTranslation } from "react-i18next";
import "../../i18n";
import { useQuiz } from "../../context/QuizContext";
import { useTimer } from "../../hooks";
import QuizHeader from "../QuestionScreen/QuizHeader";
import { ScreenTypes } from "../../types";
import backImage from "../../assets/icons/back.svg";
import { Label } from "./FixBadEatingPattern";
import {
  ButtonContainer,
  CheckBoxButton,
  EvidenceContainer,
  LinkContainer,
  ProgressBar,
  SurveryContainer,
  Todo,
} from "./FixPoorDiet";

const CheckBoxLabel = styled.label`
  padding: 18px;
  display: flex;
  align-items: center; /* Align items vertically */
  cursor: pointer;

  @media ${device.md} {
    padding: 14px;
  }

  input {
    margin-right: 10px; /* Add space between checkbox and text */
  }
`;
const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const BackIcon = styled.img`
  width: 24px; /* Set the width of the icon */
  height: 24px; /* Set the height of the icon */
  margin-right: 8px; /* Add some margin on the right side */
  /* Add any other styles you want for the back icon */
`;

const TodoStyle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Align todo items to the left */
  margin-bottom: 40px;
`;

const Title = styled.h1`
  width: 35rem;
  text-align: center;
  padding: 1rem;
  @media ${device.md} {
    font-size: 1.4rem;
    width: 20rem;
  }
`;

const pulseAnimation = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
`;

const AnimatedWarningText = styled(HighlightedText)`
  width:30rem;
  text-aling:center;
  font-weight: 700;
  margin-top: 2rem;
  color: ${({ theme }) => theme.colors.danger};
  animation: ${pulseAnimation} 1s infinite;
  @media ${device.md}{
    width:100%
  }
`;

const FixBodyComponent: FC = () => {
  const todoList: Todo[] = [
    {
      title:
        "Need to get more step-by-step guidance and track your true progression toward complete remission",
      evidence: "",
      linkUrl: [],
    },
  ];
  const [checkedTodoes, setCheckedTodoes] = useState<boolean[]>([]);

  const handleCheckBox = (index: number) => {
    const updatedCheckedTodoes = [...checkedTodoes];
    updatedCheckedTodoes[index] = !updatedCheckedTodoes[index];
    setCheckedTodoes(updatedCheckedTodoes);
  };

  const {
    choosenPath,
    setCurrTime,
    ProgressCount,
    setProgressCount,
    quizDetails,
    timer,
    setTimer,
    setEndTime,
    setCurrentScreen,
  } = useQuiz();

  useTimer(timer, quizDetails, setEndTime, setTimer);
  const date = new Date();

  const isSameDay =
    localStorage.getItem("currTime") ===
    String(date.getMonth()) + String(date.getDate());

  const handleSubmit = () => {
    const ProgressCount = localStorage.getItem("progress");
    localStorage.setItem("progress", String(Number(ProgressCount) + 1));
    const updatedProgressCount = localStorage.getItem("progress");
    setProgressCount(Number(updatedProgressCount));

    const allChecked = checkedTodoes.every((isChecked) => isChecked);
    if (allChecked) {
      localStorage.setItem(
        "currTime",
        String(date.getMonth()) + String(date.getDate())
      );
      setCurrTime(localStorage.getItem("currTime") || "");
      if (choosenPath === 1) {
        setCurrentScreen(ScreenTypes.RecommendationScreen4);
      } else {
        setCurrentScreen(ScreenTypes.RecommendationScreen);
      }
    } else {
    }
  };

  const gotoBackTodo = () => {
    setCurrentScreen(ScreenTypes.GetMedicalTreatmentComponent);
  };
  const { t } = useTranslation();
  return (
    <PageCenter>
      <CenterCardContainer>
        <div>
          <HeaderContainer>
            <ProgressBar>
              {t(`Progress Day`)}
              <HighlightedText> : {ProgressCount}</HighlightedText>
            </ProgressBar>
            <QuizHeader timer={timer} />
          </HeaderContainer>
          <div>
            <Title>{t("8. Continue fixing your body")}</Title>
          </div>
        </div>

        <TodoStyle>
          {todoList.map((todo, index) => (
            <SurveryContainer>
              <CheckBoxLabel key={index}>
                <CheckBoxButton
                  key={index}
                  onClick={() => handleCheckBox(index)}
                >
                  <Label>{t(todo.title)}</Label>
                  {checkedTodoes[index] ? "✔️" : ""}
                </CheckBoxButton>
              </CheckBoxLabel>

              <EvidenceContainer>
                <HighlightedText>{t(`${todo.evidence}`)}</HighlightedText>
              </EvidenceContainer>
              <LinkContainer>
                {todo.linkUrl.map((link, index) => (
                  <div key={index}>
                    {Object.entries(link).map(([key, value]) => (
                      <a key={key} href={value}>
                        {t(`${key}`)}
                      </a>
                    ))}
                  </div>
                ))}
              </LinkContainer>
            </SurveryContainer>
          ))}
        </TodoStyle>
        <ButtonContainer>
          <Button
            text={t("Back")}
            iconPosition="left"
            onClick={gotoBackTodo}
          />
          <Button
            text={t("Submit")}
            iconPosition="right"
            onClick={handleSubmit}
            disabled={
              !checkedTodoes.every((isChecked) => isChecked) || isSameDay
            }
          />
        </ButtonContainer>
        <AnimatedWarningText>
          <p>
          {t("After pressing the send button, you will not be able to send again for the current day.")}</p>
        </AnimatedWarningText>
        <AnimatedWarningText>
          <p>
          {t("Please make sure that you have carried out most or all of the instructions as much as possible for the current day before pressing the send button.")}</p>
        </AnimatedWarningText>
      </CenterCardContainer>
    </PageCenter>
  );
};

export default FixBodyComponent;
