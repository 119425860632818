import styled from "styled-components";
import { device } from "../../../styles/BreakPoints";
import { FC } from "react";
import {
  CenterCardContainer,
  HighlightedText,
  PageCenter,
} from "../../../styles/Global";
import { DirectionTitle, FormContainer, StyledInput } from "./recommendation5";
import lowcarb from '../../../assets/images/low_carb.jpg';
import MailChimp from "../../Subscription/MailChimp";
import Button from "../../ui/Button";
import { useQuiz } from "../../../context/QuizContext";
import { ScreenTypes } from "../../../types";
import { useTranslation } from "react-i18next";
const ImageContainer = styled.div`
  width: 30rem;
  border-radius: 10rem;
  @media ${device.md} {
    width: 19rem;
  }
`;
const RecommendationScreen: FC= () => {
  const {setCurrentScreen} = useQuiz();

  const goback = () => {

    setCurrentScreen(ScreenTypes.FixPoorDietComponent);
  
  }
  const {t} = useTranslation();
  return (
    <PageCenter >
      <CenterCardContainer>
      <ImageContainer>
          <img src={lowcarb} alt="Description" />
        </ImageContainer>
        <DirectionTitle>
          <HighlightedText>
          {t("Follow")} <a href="https://www.healthline.com/nutrition/low-carb-diet-meal-plan-and-menu">{t("Low carb")}</a> {t("diet only.")}
          </HighlightedText>
        </DirectionTitle>
        <MailChimp></MailChimp>

        <Button text={t('Back')} onClick={goback} style={{marginTop:'1rem'}}/>

        
      </CenterCardContainer>
    </PageCenter>
  );
};

export default RecommendationScreen;
