import { FC } from 'react'

import { Flex } from '../../../styles/Global'
import {  formatTime } from '../../../utils/helpers'

import Counter from './Counter'

interface QuizHeaderProps {
  
  timer: number
}

const QuizHeader: FC<QuizHeaderProps> = ({  timer }) => {
  return (
    <Flex spaceBetween gap="6px">
      <Flex>
        <Counter time={`${formatTime(timer)}`} />
      </Flex>
    </Flex>
  )
}

export default QuizHeader
