import { Topic } from '.';

export const DiabetesManagement: Topic = {
  topic: 'Diabetes Management',
  totalTime: 86400,
  questions: [
    {
      question: 'Choose your status and goal!',
      title: '',
      message: '',
      choices: ['I\'m at prediabetes, or I need diabetes prevention only',
        'I\'ve diabetes type 1',
        'I\'ve diabetes type 2','I don\'t know',
      ],
      type: 'MCQs',
    },
    {
      question: 'Choose the Remission Program mood',
      title: '',
      message: 'Great! You can start the Remission Program now!',
      choices: ['Easy mood (80% Complete Remission guaranteed,100% Partial Remission guaranteed): No need for frequent blood sugar and body weight measurements throughout the 60-day program.', 'Advanced mood (100% Complete Remission guaranteed): Frequent blood sugar and body weight measurements are required throughout the 60-day program. '
      ],
      type: 'MCQs',
    },
    {
      question: 'Are you obese or overweight?',
      title: "2. Fix Obesity",
      message: "",
      choices: ['Yes, I’m obese or overweight', 'No, I’m not obese or overweight'
      ],
      type: 'MCQs',
    },
    {
      question: 'Are you on diabetes medication?',
      title: '1. Fix Drug',
      message: '',
      choices: [
        'No',
        'Yes'
      ],
      type: 'MCQs',
    },
    {
      question: 'Do you take insulin?',
      title: '',
      message: '',
      choices: ['Yes',
        'No I do not take insulin'
      ],
      type: 'MCQs',
    },
    {
      question: 'Do you take Metformin only or Other sugar-lowering or mixed hypoglycemic drugs?',
      title: '',
      message: '',
      choices: [
        'I take Metformin only',
        'I take other sugar-lowering or mixed hypoglycemic drugs',
      ],
      type: 'MCQs',
    },
  ],
  level: '',
  totalScore: 0
};





