import * as React from "react";
import MailchimpSubscribe from "react-mailchimp-subscribe";
import styled from "styled-components";
import Button from "../ui/Button";
import { device } from "../../styles/BreakPoints";
import { useTranslation } from "react-i18next";

const url: string ="https://remissionclinic.us9.list-manage.com/subscribe/post?u=6bf39cf3bf1e5d7261fe3b627&amp;id=487a35cb42&amp;f_id=00330fe0f0";

const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

const InputContainer = styled.div`
  width: 30rem;
  display: flex;
  flex-direction: column;
  padding: 2rem;

  input {
    margin-bottom: 1rem;
    padding: 0.5rem;
    border: 0.5px solid pink;
    border-radius: 0.4rem;
    background: white;
  }
  @media ${device.md} {
    width: 20rem;
    display: flex;
    flex-direction: column;
    padding: 2rem;

    input {
      margin-bottom: 1rem;
      padding: 0.5rem;
      border: 0.5px solid pink;
      border-radius: 0.4rem;
      background: white;
    }
  }
`;

interface FormData {
  EMAIL: string;
  FNAME?: string;
  LNAME?: string;
}

const SimpleForm: React.FC<{ onSubmitted: (formData: FormData) => void }> = ({
  onSubmitted,
}) => {
  const [email, setEmail] = React.useState("");
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSubmitted({ EMAIL: email, FNAME: firstName, LNAME: lastName });
  };
 const {t}  = useTranslation();
  return (
    <FormContainer onSubmit={handleSubmit}>
      <InputContainer>
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <input
          type="text"
          placeholder="First Name"
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
        />
        <input
          type="text"
          placeholder="Last Name"
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
        />
      </InputContainer>
      <Button text={t("Subscribe")} onClick={() => {}} />
    </FormContainer>
  );
};
const MailChimp: React.FC = () => (
  <MailchimpSubscribe<FormData>
    url={url}
    render={({ subscribe, status, message }) => (
      <div>
        <SimpleForm onSubmitted={(formData) => subscribe(formData)} />
        {status === "sending" && (
          <div style={{ color: "blue" }}>sending...</div>
        )}
        {status === "error" && <div style={{ color: "red" }}>{}</div>}
        {status === "success" && (
          <div style={{ color: "green" }}>
            Subscribed!
          </div>
        )}
      </div>
    )}
  />
);

export default MailChimp;




