import styled from "styled-components";
import { device } from "../../../styles/BreakPoints";
import { FC } from "react";
import {
  CenterCardContainer,
  HighlightedText,
  PageCenter,
} from "../../../styles/Global";
import { DirectionTitle, FormContainer, StyledInput } from "./recommendation5";
import Button from "../../ui/Button";
import MailChimp from "../../Subscription/MailChimp";
import { useQuiz } from "../../../context/QuizContext";
import { ScreenTypes } from "../../../types";
import { useTranslation } from "react-i18next";

const RecommendationScreen3: FC = () => {
  const { setCurrentScreen } = useQuiz();

  const goback = () => {
    setCurrentScreen(ScreenTypes.QuestionScreen);
  };
  const { t } = useTranslation();
  return (
    <PageCenter>
      <CenterCardContainer>
        <DirectionTitle>
          {t("The first step in your diabetes Remission is to be off-insulin.")}
          <HighlightedText>
            {t(
              "⚠ Ask your doctor to stop it gradually and start Metformin only."
            )}
          </HighlightedText>
          {t(
            "When done, come back to continue the Remission Program. Why? Learn more."
          )}

          <HighlightedText>
            {t(
              "Subscribe to get the latest free weekly Diabetes Remission tips"
            )}
          </HighlightedText>
        </DirectionTitle>
        <MailChimp></MailChimp>
        <Button
          text={t("Back To Program")}
          onClick={goback}
          style={{ marginTop: "1rem" }}
        />
      </CenterCardContainer>
    </PageCenter>
  );
};

export default RecommendationScreen3;
