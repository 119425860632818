import React, { FC, useState } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import {
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import axios from "axios";
import styled from "styled-components";
import { useQuiz } from "../../context/QuizContext";
import { ScreenTypes } from "../../types";
import StripeLogo from "../../assets/images/stripe.png";
import { device } from "../../styles/BreakPoints";
import {
  CenterCardContainer,
  HighlightedText,
  PageCenter,
} from "../../styles/Global";
import { useTranslation } from "react-i18next";

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 1rem;
  input {
    background-color: ${({ theme }) => theme.colors.white};
    padding: 0.5rem;
    border-radius: 0.5rem;
    border: 1px solid ${({ theme }) => theme.colors.themeColor};
    font-size: 1rem;
    outline: none;
  }
`;

const LabelContainer = styled.div`
  padding-bottom: 0.5rem;
`;
const PayementButton = styled.button`
  padding: 0.9rem;
  text-align: center;
  background: ${({ theme }) => theme.colors.themeGradient};
  color: ${({ theme }) => theme.colors.buttonText};
  width: 8rem; /* Adjust the width as needed */
  margin: 0 auto; /* Center the button horizontally */
  margin-top: 2rem;
  display: block; /* Ensure button takes full width */
  border-radius: 0.5rem;
`;

const PaymentHeader = styled.div`
  display: flex;
  flex-direction: column;
  width: 30rem;
  align-items: center;
  justify-content: space-between;
  @media ${device.md} {
    width: 100%;
  }
`;
const LogoContainer = styled.div`
  text-align: center;
  margin-bottom: 2rem;
  @media ${device.md} {
    margin-bottom: 30px;
  }
`;

const LogoImage = styled.img`
  width: 10rem;
  @media ${device.md} {
    width: 100%;
  }
`;

const Title = styled.h2`
  text-align: center;
  width: 20rem;
  margin-bottom: 1.5rem;
  @media ${device.md} {
    width: 100%;
  }
`;

const Stripe = styled.h1`
  font-size: 2rem;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.themeColor};
  text-align: center;
  width: 20rem;
  margin-bottom: 1.5rem;
  @media ${device.md} {
    width: 100%;
  }
`;

const ErrorContainer = styled.div`
  color: red;
  margin-top: 1rem;
  text-align: center;
`;

interface CheckoutFormProps {}

export const CheckoutForm: FC<CheckoutFormProps> = () => {
  const stripe = useStripe();
  const elements = useElements();

  const [errorMessage, setErrorMessage] = useState<string>("");
  const [emailInput, setEmailInput] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false); // New state for loading

  const { setCurrentScreen } = useQuiz();
  const {t} = useTranslation();

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (elements == null || stripe == null) {
      return;
    }

    setLoading(true);

    const { error: submitError } = await elements.submit();
    if (submitError?.message) {
      setErrorMessage(submitError.message);
      setLoading(false);
      return;
    }

    try {
      const response = await axios.post(
        "https://remission-clinic-bh73.onrender.com/payment",
        {
          currency: "usd",
          email: emailInput,
          amount: 4900,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.data.success) {
        localStorage.setItem("payed", "true");

        setCurrentScreen(ScreenTypes.ThankYouPage);
      }
      // const { client_secret: clientSecret } = response.data;

      // const { error } = await stripe.confirmPayment({
      //   elements,
      //   clientSecret,
      //   confirmParams: {
      //     return_url: `${window.location.origin}/success`,
      //   },
      // });

      // if (error) {
      //   setErrorMessage(error.message || "");
      // } else {
      //   setCurrentScreen(ScreenTypes.FixPoorDietComponent)
      // }
    } catch (error) {
      setErrorMessage("An error occurred during payment.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <PageCenter>
      <CenterCardContainer>
        <PaymentHeader>
          <LogoContainer>
            <Stripe>{t("Powered by Stripe")}</Stripe>
          </LogoContainer>
          <Title>{("30% discount is valid only for limited days!")}</Title>
          <HighlightedText>
            <Title>{("Pay $49 to proceed")}</Title>
          </HighlightedText>
        </PaymentHeader>
        <form onSubmit={handleSubmit} className="px-4">
          <div>
            <LabelContainer>
              <label htmlFor="email-input">Email</label>
            </LabelContainer>
            <InputContainer>
              <input
                value={emailInput}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setEmailInput(e.target.value)
                }
                type="email"
                id="email-input"
                placeholder="johndoe@gmail.com"
              />
            </InputContainer>
          </div>
          <PaymentElement />

          <PayementButton
            type="submit"
            disabled={!stripe || !elements || loading}
          >
            {t("Pay Now")}
          </PayementButton>
          {loading && (
            <div>
              <ClipLoader
                color="green"
                loading={loading}
                size={20}
                aria-label="Loading Spinner"
                data-testid="loader"
              />{" "}
            </div>
          )}
          {errorMessage && <ErrorContainer>{errorMessage}</ErrorContainer>}
        </form>
      </CenterCardContainer>
    </PageCenter>
  );
};

export default CheckoutForm;
