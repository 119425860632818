import React, { FC, useState } from "react";
import { CenterCardContainer, HighlightedText, PageCenter } from "../../styles/Global";
import Button from "../ui/Button";
import { Next, StartIcon } from "../../config/icons";
import styled from "styled-components";
import { device } from "../../styles/BreakPoints";
import { useQuiz } from "../../context/QuizContext";
import { ScreenTypes } from "../../types";
import { useTranslation } from "react-i18next";
import "../../i18n";
import { useTimer } from "../../hooks";
import QuizHeader from "../QuestionScreen/QuizHeader";
import backImage from "../../assets/icons/back.svg";
import { ButtonContainer, CheckBoxButton, EvidenceContainer, LinkContainer, SurveryContainer, Todo } from "./FixPoorDiet";

const CheckBoxLabel = styled.label`
  padding: 18px;
  display: flex;
  align-items: center; /* Align items vertically */
  cursor: pointer;

  @media ${device.md} {
    padding: 14px;
  }

  input {
    margin-right: 10px; /* Add space between checkbox and text */
  }
`;

const TodoStyle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Align todo items to the left */
  margin-bottom: 40px;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;




const BackIcon = styled.img`
  width: 24px; /* Set the width of the icon */
  height: 24px; /* Set the height of the icon */
  margin-right: 8px; /* Add some margin on the right side */
  /* Add any other styles you want for the back icon */
`;




const Label = styled.p`
  font-size: 16px;
  text-align: center; /* Center the label text */
`;

const Title = styled.h1`
width: 35rem;
text-align: center;
padding: 1rem;
@media ${device.md} {
  font-size: 1.4rem;
  width: 20rem;
}
`;

const ProgressBar = styled.h3`
font-size: 1.5rem;
text-align: left;
`;
const FixProteinIntakeComponent: FC = () => {
  

  const todoList: Todo[] = [
    {title:"Stop all red meat products, and reduce poultry (without skin)",
    evidence: "",
    linkUrl :[]
    
  },
    {title:"Make protein sources only from Legumes, Egg white, and Fish",
  evidence: "",
  linkUrl :[]
  }
  ];
  const { setCurrentScreen } = useQuiz();
  const [checkedTodoes, setCheckedTodoes] = useState<boolean[]>(Array(todoList.length).fill(false));


  const handleCheckBox = (index: number) => {
    const updatedCheckedTodoes = [...checkedTodoes];
    updatedCheckedTodoes[index] = !updatedCheckedTodoes[index];
    setCheckedTodoes(updatedCheckedTodoes);
  };

  const gotoNextTodo = () => {
    const allChecked = checkedTodoes.every((isChecked) => isChecked);
    if (allChecked) {
      setCurrentScreen(ScreenTypes.FixStressComponent);
    } else {
      alert("Please select all checkboxes before proceeding.");
    }
  };

  const gotoBackTodo = () => {
    setCurrentScreen(ScreenTypes.FixBadEatingPatternComponent);
  };

  const { t } = useTranslation();
  const ProgressCount = useQuiz().ProgressCount;

  const { quizDetails, timer, setTimer, setEndTime } = useQuiz();

  useTimer(timer, quizDetails, setEndTime, setTimer);

  return (
    <PageCenter >
      <CenterCardContainer>
      <div>
          <HeaderContainer>
            <ProgressBar>{t(`Progress Day`)}
              <HighlightedText> : {ProgressCount}</HighlightedText>
            </ProgressBar>
            <QuizHeader timer={timer} />
          </HeaderContainer>
          <div>
            <Title>
              {t("5. Fixing Protein intake")}
            </Title>
          </div>
        </div>


        <TodoStyle>
          {todoList.map((todo, index) => (
            <SurveryContainer>
            <CheckBoxLabel key={index}>
              <CheckBoxButton key={index} onClick={() => handleCheckBox(index)}>
                <Label>{t(todo.title)}</Label>
                {checkedTodoes[index] ? "✔️" : ""}
              </CheckBoxButton>
            </CheckBoxLabel>

            <EvidenceContainer>
              <HighlightedText>{t(`${todo.evidence}`)}</HighlightedText>
            </EvidenceContainer>
            <LinkContainer>
              {todo.linkUrl.map((link, index) => (
                <div key={index}>
                  {Object.entries(link).map(([key, value]) => (
                    <a key={key} href={value}>
                      {t(`${key}`)}
                    </a>
                  ))}
                </div>
              ))}
            </LinkContainer>
          </SurveryContainer>
          ))}
        </TodoStyle>
        <ButtonContainer>
          <Button
            text={t("Back")}
            iconPosition="left"
            onClick={gotoBackTodo}
          />
          <Button
            text={t("Next")}
            iconPosition="right"
            onClick={gotoNextTodo}
            disabled={!checkedTodoes.every((isChecked) => isChecked)}
          />
        </ButtonContainer>
      </CenterCardContainer>
    </PageCenter>
  );
};

export default FixProteinIntakeComponent;
