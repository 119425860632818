import React from "react";
import { loadStripe, StripeElementsOptions } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { CheckoutForm } from "./paymentForm";

type CheckoutProps = {};

const options: StripeElementsOptions = {
  // Customize your options here
  mode: "payment",
  amount: 4900,
  currency: "usd",
  // Fully customizable with appearance API.
  appearance: {
    theme: "stripe",
    variables: {
      colorPrimary: '#0570de',
      colorBackground: '#ffffff',
      colorText: '#30313d',
      colorDanger: '#df1b41',
      fontFamily: 'Ideal Sans, system-ui, sans-serif',
      spacingUnit: '0.25rem',
      borderRadius: '4px',

    }
    
    
  },
};

const Checkout: React.FC<CheckoutProps> = () => {
  // const PUBLIC_KEY = "pk_live_51ONzd3CUxyjARwK1kFFDtObWOAg7xjekNXOSdQEM6RlEh33TKWqd9OqdPmv1XqZLfXlndEH5NfVOMgfS5zPP1OvC003g6kn181";
  const PUBLIC_KEY ="pk_live_51ONzd3CUxyjARwK1kFFDtObWOAg7xjekNXOSdQEM6RlEh33TKWqd9OqdPmv1XqZLfXlndEH5NfVOMgfS5zPP1OvC003g6kn181";
  const stripeTestPromise = loadStripe(PUBLIC_KEY);
  // import meta.env.VITE_STRIPE_PK is the publishable key you can either directly paste your stripe key here but not recommending if you are planning to upload the code on github as it should remain only available to you or save the key in .env file

  return (
    <div>
      <Elements stripe={stripeTestPromise} options={options}>
        <CheckoutForm />
      </Elements>
    </div>
  );
};

export default Checkout;

// const PUBLIC_KEY = "pk_test_51OONKPGZxsVP8DW6ulGAuDvscUZIdW4KFBIn4pydP51xFwtXuJi8hPLykWcCm0tigFjSvupDPdo72qyehjPYKxwc00Boul8elS";
