import { FC } from "react";
import {
  CenterCardContainer,
  HighlightedText,
  PageCenter,
} from "../../../styles/Global";
import { DirectionTitle } from "./recommendation5";
import MailChimp from "../../Subscription/MailChimp";
import Button from "../../ui/Button";
import { useQuiz } from "../../../context/QuizContext";
import { ScreenTypes } from "../../../types";
import { useTranslation } from "react-i18next";

const RecommendationScreen2: FC = () => {
  const { setCurrentScreen } = useQuiz();
  const goback = () => {
    setCurrentScreen(ScreenTypes.QuestionScreen);
  };
  const { t } = useTranslation();

  return (
    <PageCenter>
      <CenterCardContainer>
        <DirectionTitle>
          {t(
            "Type 1 is non-reversible yet in the current medical knowledge, you must take insulin as described by your doctor."
          )}

          <HighlightedText> 
            {t("For Type-1 healthy nutrition program that aims to only control blood glucose levels, prevent or decrease type-1 complications, and achieve healthy longevity.")}
          </HighlightedText>
          <p>
            {t("Join the waiting list in the meantime to get free weekly easy healthy eating tips to control type 1 sugar levels directly to your inbox!")}
          </p>

          <p>

          </p>
          <HighlightedText>
            {t(
              "Subscribe to Type 1 email newsletter"
            )}
          </HighlightedText>
        </DirectionTitle>
        <MailChimp></MailChimp>
        <Button
          text={t("Back To Program")}
          onClick={goback}
          style={{ marginTop: "1rem" }}
        />
      </CenterCardContainer>
    </PageCenter>
  );
};

export default RecommendationScreen2;
