import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

export const StyledSelect = styled.select`
  padding: 8px;
  font-size: 16px;
  color: ${({ theme }) => theme.colors.themeColor};
  background: ${({ theme }) => theme.colors.background};
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
`;

const LanguageSelector: React.FC = () => {
  const { i18n } = useTranslation();
  const [selectedLanguage, setSelectedLanguage] = useState<string>(i18n.language);

  const chooseLanguage: React.ChangeEventHandler<HTMLSelectElement> = (e) => {
    e.preventDefault();
    const selectedValue = e.target.value;

    setSelectedLanguage(selectedValue);
    localStorage.setItem("lang", selectedValue);
    i18n.changeLanguage(selectedValue);

    // Set document direction based on selected language
    document.dir = selectedValue === "ar" ? "rtl" : "ltr";
    localStorage.setItem('direction',selectedValue);
  };

  return (
    <StyledSelect value={selectedLanguage} onChange={chooseLanguage}>
      <option value="de">Deutsch</option>
      <option value="en">English</option>
      <option value="ar">عربي</option>
    </StyledSelect>
  );
};

export default LanguageSelector;
