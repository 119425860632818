import React, { FC, useState } from "react";
import {
  CenterCardContainer,
  Flex,
  HighlightedText,
  PageCenter,
} from "../../styles/Global";
import Button from "../ui/Button";
import { Next, StartIcon } from "../../config/icons";
import styled from "styled-components";
import { device } from "../../styles/BreakPoints";
import { useTranslation } from "react-i18next";
import "../../i18n";
import { useQuiz } from "../../context/QuizContext";
import { ScreenTypes } from "../../types";
import { useTimer } from "../../hooks";
import QuizHeader from "../QuestionScreen/QuizHeader";
import backImage from "../../assets/icons/back.svg";
import { Dict } from "styled-components/dist/types";

const CheckBoxLabel = styled.label`
  padding: 10px;
  align-items: center; /* Align items vertically */
  cursor: pointer;
  display: flex;
  flex-direction: column;

  @media ${device.md} {
    padding: 14px;
  }

  input {
    margin-right: 10px; /* Add space between checkbox and text */
  }
`;

const TodoStyle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Align todo items to the left */
`;

const Label = styled.p`
  font-size: 16px;
  text-align: center; /* Center the label text */
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const BackIcon = styled.img`
  width: 24px; /* Set the width of the icon */
  height: 24px; /* Set the height of the icon */
  margin-right: 8px; /* Add some margin on the right side */
  /* Add any other styles you want for the back icon */
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 1.5rem;
  gap:0.8rem;
`;

export const CheckBoxButton = styled.button`
  width: 33rem;
  padding: 1rem;
  align-items: center;
  cursor: pointer;
  display: flex;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 1rem;
  color: ${({ theme }) => theme.colors.themeText};
  background: ${({ theme }) => theme.colors.themeGradient};
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #f0f0f0;
  }

  @media ${device.md} {
    padding: 14px;
    width: 20rem;
  }

  input {
    display: none;
  }
`;
export const EvidenceContainer = styled.div`
  width: 33rem;
  padding-left: 1rem;
  padding-right: 1rem;

  @media ${device.md} {
    width: 20rem;
  }
`;
export const LinkContainer = styled.div`
  width: 33rem;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-top: 10px; /* Adding margin between links */
  
  a {
    display: block;
    margin-bottom: 0.6rem; /* Adding margin between each link */
    /* Additional styles for links */
  }

  @media ${device.md} {
    width: 20rem;
  }
`;

const Title = styled.h1`
  text-align: center;
  padding: 1rem;
  @media ${device.md} {
    font-size: 1.4rem;
  }
`;
export const DirectionTitle = styled.div`
  width: 35rem;
  text-align: left;
  padding: 1rem;
  font-size: 1.3rem;
  font-weight: 700;
  @media ${device.md} {
    font-size: 1.3rem;
    width: 20rem;
    text-align: left;
  }
`;
export const ProgressBar = styled.h3`
  font-size: 1.5rem;
  text-align: left;
`;

export const SurveryContainer = styled.div`
  margin-bottom: 20px;
`;

export type Todo = {
  title: string;
  evidence: string;
  linkUrl: Dict[];
};
const FixPoorDietComponent: FC = () => {
  const todoList: Todo[] = [
    {
      title:
        "Take 12 grams (4 teaspoons) of Fenugreek seed powder or seeds every day.",
      evidence:
        "Evidence: Top-rated (170) score of 5-star evidence-based health benefits in diabetes type-2 treatment and prevention",
      linkUrl: [
        {
          "Fenugreek-seeds-evidence-based-health-benefits":
            "https://www.antioxida.com/Fenugreek-seeds-evidence-based-health-benefits-review-up-to-date/product-id/602",
        },
      ],
    },
    {
      title:
        "Take 3 grams (1 teaspoon) of dried ground Cumin every day (once or in divided doses).",
      evidence:
        "Evidence: 2nd Top-rated (105) score of 5-star evidence-based health benefits in diabetes type-2 treatment and prevention.",
      linkUrl: [
        {
          "Dried-ground-Cumin-Jerra--evidence-based-health-benefits":
            "https://www.antioxida.com/dried-ground-Cumin-Jerra--evidence-based-health-benefits-review-up-to-date/product-id/533",
        },
      ],
    },
    {
      title:
        "Take 1 gram (half teaspoon) of Dried Cinnamon Ground every day (once or in divided doses).",
      evidence:
        "Evidence: 5-star rating for evidence-based health benefits in obesity and diabetes type-2 treatment and prevention.",
      linkUrl: [
        {
          "Dried--Cinnamon-ground-evidence-based-health-benefits":
            "https://www.antioxida.com/Dried--Cinnamon-ground-evidence-based-health-benefits-review-up-to-date/product-id/520",
        },
      ],
    },
  ];
  const { setCurrentScreen } = useQuiz();

  const [checkedTodoes, setCheckedTodoes] = useState<boolean[]>(
    Array(todoList.length).fill(false)
  );

  const handleCheckBox = (index: number) => {
    const updatedCheckedTodoes = [...checkedTodoes];
    updatedCheckedTodoes[index] = !updatedCheckedTodoes[index];
    setCheckedTodoes(updatedCheckedTodoes);
  };

  const gotoNextTodo = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const payed = localStorage.getItem("payed");
    const allChecked = checkedTodoes.every((isChecked) => isChecked);
    if (allChecked) {
      if (!payed) {
        setCurrentScreen(ScreenTypes.StripeContainerScreen);
      } else {
        setCurrentScreen(ScreenTypes.FixPoorDietComponent2);
      }
    }
  };

  const gotoBackTodo = () => {
    setCurrentScreen(ScreenTypes.HowToUseScreen);
  };

  const { t } = useTranslation();

  const ProgressCount = useQuiz().ProgressCount;

  const { quizDetails, timer, setTimer, setEndTime } = useQuiz();

  useTimer(timer, quizDetails, setEndTime, setTimer);

  localStorage.setItem("remaining_time", JSON.stringify(timer) as string);

  return (
    <PageCenter>
      <CenterCardContainer>
        <div>
          <HeaderContainer>
            <ProgressBar>
              {t(`Progress Day`)}
              <HighlightedText> : {ProgressCount}</HighlightedText>
            </ProgressBar>
            <QuizHeader timer={timer} />
          </HeaderContainer>
          <div>
            <Title>{t("3. Fix Poor diet")}</Title>

            <DirectionTitle>
              <HighlightedText>
                {t(
                  "Daily herbs intake with the highest beneficial potential to treat and prevent diabetes type 2 (lowers glucose, lowers cholesterol, removes accumulated Advanced glycation end products (AGEs) “clogging cells”, etc)."
                )}
              </HighlightedText>
            </DirectionTitle>
          </div>
        </div>
        {todoList.map((todo, index) => (
          <SurveryContainer>
            <CheckBoxLabel key={index}>
              <CheckBoxButton key={index} onClick={() => handleCheckBox(index)}>
                <Label>{t(todo.title)}</Label>
                {checkedTodoes[index] ? "✔️" : ""}
              </CheckBoxButton>
            </CheckBoxLabel>

            <EvidenceContainer>
              <HighlightedText>{t(`${todo.evidence}`)}</HighlightedText>
            </EvidenceContainer>
            <LinkContainer>
              {todo.linkUrl.map((link, index) => (
                <div key={index}>
                  {Object.entries(link).map(([key, value]) => (
                    <a key={key} href={value}>
                      {t(`${key}`)}
                    </a>
                  ))}
                </div>
              ))}
            </LinkContainer>
          </SurveryContainer>
        ))}
        <ButtonContainer>
          <Button
            text={t("Back")}
            iconPosition="left"
            onClick={gotoBackTodo}
          />
          <Button
            text={t("Next")}
            iconPosition="right"
            onClick={gotoNextTodo}
            disabled={!checkedTodoes.every((isChecked) => isChecked)}
          />
        </ButtonContainer>
      </CenterCardContainer>
    </PageCenter>
  );
};

export default FixPoorDietComponent;
