import { Dispatch, SetStateAction } from 'react'
import { Question } from '../data/QuizQuestions'


export enum ScreenTypes {
  ThankYouPage,
  HowToUseScreen,
  TermsOfUseScreen,
  PrivacyPolicy,
  SplashScreen,
  QuizTopicsScreen,
  QuizDetailsScreen,
  QuestionScreen,
  RecommendationScreen,
  LandingPageScreen,
  RecommendationScreen1,
  RecommendationScreen2,
  RecommendationScreen3,
  RecommendationScreen4,
  RecommendationScreen5,
  RecommendationScreen6,
  PaymentFormScreen,
  StripeContainerScreen,
  FixPoorDietComponent,
  FixPoorDietComponent2,
  FixPoorDietComponent3,
  FixPoorDietComponent4,
  FixPoorDietComponent5,
  FixProteinIntakeComponent,
  GetMedicalTreatmentComponent,
  FixBodyComponent,
  FixStressComponent,
  FixBadEatingPatternComponent

}

export interface Result extends Question {
  selectedAnswer: string[]
  isMatch: boolean
}

export type QuizContextTypes = {
  messagePath:number,
  setMessagePath: Dispatch<SetStateAction<number>>,
  choosenPath: number,
  setChoosenPath: Dispatch<SetStateAction<number>>,
  currTime: string,
  setCurrTime: Dispatch<SetStateAction<string>>,
  ProgressCount : number,
  setProgressCount : Dispatch<SetStateAction<number>>,
  currentScreen: ScreenTypes
  setCurrentScreen: Dispatch<SetStateAction<ScreenTypes>>
  quizTopic: string
  selectQuizTopic: (type: string) => void
  questions: Question[]
  setQuestions: Dispatch<SetStateAction<any[]>>
  result: Result[]
  setResult: Dispatch<SetStateAction<any[]>>
  timer: number
  setTimer: Dispatch<SetStateAction<number>>
  endTime: number
  setEndTime: (type: number) => void
  quizDetails: {

    totalTime: number
    selectedQuizTopic: string
  }
}
