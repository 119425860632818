import { FC } from "react";
import { CenterCardContainer, PageCenter } from "../../styles/Global";
import styled from "styled-components";
import Button from "../ui/Button";
import { useQuiz } from "../../context/QuizContext";
import { ScreenTypes } from "../../types";
import { device } from "../../styles/BreakPoints";
import { useTranslation } from "react-i18next";

const StyledListItem = styled.li`
font-size: 1.1rem;
padding: 0.5rem;
  list-style-type: \\27A2;
  margin-bottom: 8px;

  @media ${device.md} {
    width: 100%;
  }
`;

const Title = styled.h1`
  padding: 2rem;
  text-align: center;
  width: 30rem;

  @media ${device.md} {
    width: 100%;
  }
`;

const HowToUseContainer = styled.div`
  width: 30rem;

  @media ${device.md} {
    width: 100%;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 30rem;

  @media ${device.md} {
    width: 100%;
  }
`;

const ListContainer = styled.ul`
padding: 1rem;
`

const HowToUseScreen: FC = () => {
  const { setCurrentScreen } = useQuiz();

  const gotoNextPage = () => {
    setCurrentScreen(ScreenTypes.FixPoorDietComponent);
  };

  const gotoBackPage = () => {
    setCurrentScreen(ScreenTypes.QuestionScreen);
  };
 const {t} = useTranslation();
  return (
    <PageCenter>
      <CenterCardContainer>
        <HowToUseContainer>
          <Title>{t("How to use")}</Title>
          <ListContainer>
            <StyledListItem>
              {t("The more checkboxes you achieve each day, the more you get closer to remission.")}
            </StyledListItem>
            <StyledListItem>
              {t("You may start gradually adding on more checkboxes until you reach 100% of daily instructions.")}
            </StyledListItem>
            <StyledListItem>
              {t("You can still find significant improvements in diabetes control and complications prevention If, for example, you are achieving only 80 or less from the daily instructions.")}
            </StyledListItem>
            <StyledListItem>
              {t("In case of no 100% daily commitment to the full program instructions, progression into remission may still occur but will take a longer time")}
            </StyledListItem>
          </ListContainer>
          <ButtonContainer>
            <Button text={t("Back")} onClick={gotoBackPage} />
            <Button text={t("Next")} onClick={gotoNextPage} />
          </ButtonContainer>
        </HowToUseContainer>
      </CenterCardContainer>
    </PageCenter>
  );
};

export default HowToUseScreen;
