import { createContext, useContext, Dispatch, SetStateAction } from 'react';
import { QuizContextTypes, ScreenTypes } from '../types';
export const initialState: QuizContextTypes = {
  messagePath: 1,
  setMessagePath :()=>{},
  choosenPath: 1,
  setChoosenPath: ()=>{},
  currTime: localStorage.getItem('currTime')||'',
  setCurrTime: ()=>{} ,
  ProgressCount:  Number(localStorage.getItem('progress')) || 0,
  setProgressCount:()=>{},
  currentScreen: ScreenTypes.SplashScreen,
  setCurrentScreen: () => {},
  quizTopic: 'DiabetesManagement',
  selectQuizTopic: () => {},
  questions: [],
  setQuestions: () => {},
  result: [],
  setResult: () => {},
  timer: 60,
  setTimer: () => {},
  endTime: 0,
  setEndTime: () => {},
  quizDetails: {
    totalTime: 0,
    selectedQuizTopic: 'DiabetesManagement',
  },
};

export const QuizContext = createContext<QuizContextTypes>(initialState);

export function useQuiz() {
  return useContext(QuizContext);
}




