import { useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';

import  AppLogo from '../../assets/images/logo.svg';
import { PageCenter } from '../../styles/Global';

interface LogoAnimationProps {
  logoSize: string;
}

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: scale(0);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
`;

const LogoAnimation = styled.div<LogoAnimationProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    width: ${({ logoSize }) => logoSize};
    animation: ${fadeIn} 1s ease-in-out; /* Apply the animation to the SVG */
    transition: width 1s;
  }
`;

const LogoContainer = styled.div`
  height: auto; /* Adjust the height of the logo */
  width: auto;
  padding: 2px;
  margin: 10px;
`;

const LogoImage = styled.img`
  width: 100px; /* Adjust the width of the logo */
  height: auto;
  border-radius: 100%;
`;

const SplashScreen = () => {
  const [logoSize, setLogoSize] = useState('40px');

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 900) {
        setLogoSize('100px');
      } else {
        setLogoSize('200px');
      }
    };
    handleResize();

    window.addEventListener('resize', handleResize);

   
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <PageCenter justifyCenter>
      <LogoAnimation logoSize={logoSize}>
      <LogoContainer>
          <LogoImage src={AppLogo} alt="App Logo" /> {/* Render AppLogo as an image */}
        </LogoContainer>
      </LogoAnimation>
    </PageCenter>
  );
};

export default SplashScreen;

