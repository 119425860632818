import React, { FC, useState } from "react";
import {
  CenterCardContainer,
  Flex,
  HighlightedText,
  PageCenter,
} from "../../styles/Global";
import Button from "../ui/Button";
import { Next, StartIcon } from "../../config/icons";
import styled from "styled-components";
import { device } from "../../styles/BreakPoints";
import { useTranslation } from "react-i18next";
import "../../i18n";
import { useQuiz } from "../../context/QuizContext";
import { ScreenTypes } from "../../types";
import { useTimer } from "../../hooks";
import QuizHeader from "../QuestionScreen/QuizHeader";
import backImage from "../../assets/icons/back.svg";
import {
  ButtonContainer,
  CheckBoxButton,
  DirectionTitle,
  EvidenceContainer,
  LinkContainer,
  ProgressBar,
  SurveryContainer,
  Todo,
} from "./FixPoorDiet";

const CheckBoxLabel = styled.label`
  padding: 10px;
  align-items: center; /* Align items vertically */
  cursor: pointer;
  display: flex;

  margin-bottom: 20px;

  @media ${device.md} {
    padding: 14px;
  }

  input {
    margin-right: 10px; /* Add space between checkbox and text */
  }
`;

const TodoStyle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Align todo items to the left */
`;

const Label = styled.p`
  font-size: 16px;
  text-align: center; /* Center the label text */
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const BackIcon = styled.img`
  width: 24px; /* Set the width of the icon */
  height: 24px; /* Set the height of the icon */
  margin-right: 8px; /* Add some margin on the right side */
  /* Add any other styles you want for the back icon */
`;

const Title = styled.h1`
  text-align: center;
  padding: 1rem;
  @media ${device.md} {
    font-size: 1.4;
  }
`;

const FixPoorDietComponent4: FC = () => {
  const todoList: Todo[] = [
    {
      title:
        "Stop refined carbohydrates such as bread, cakes, pasta, etc completely",
      evidence: "",
      linkUrl: [],
    },

    {
      title:
        "Stop unhealthy Saturated fats: Egg yolk, butter, Margarine, and animal fats such as chicken skin, etc.",
      evidence: "",
      linkUrl: [],
    },
    {
      title:
        "Stop raw milk and any milk-containing foods such as ice cream and chocolate-containing milk. (Yogurt is allowed).",
      evidence: "",
      linkUrl: [],
    },
    {
      title: "Stop any sugar-added or water-added non-fresh fruit juices",
      evidence: "",
      linkUrl: [],
    },
  ];
  const { setCurrentScreen } = useQuiz();

  const [checkedTodoes, setCheckedTodoes] = useState<boolean[]>(
    Array(todoList.length).fill(false)
  );

  const handleCheckBox = (index: number) => {
    const updatedCheckedTodoes = [...checkedTodoes];
    updatedCheckedTodoes[index] = !updatedCheckedTodoes[index];
    setCheckedTodoes(updatedCheckedTodoes);
  };

  const gotoNextTodo = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const allChecked = checkedTodoes.every((isChecked) => isChecked);

    if (allChecked) {
      setCurrentScreen(ScreenTypes.FixPoorDietComponent5);
    } else {
      alert("Please select all checkboxes before proceeding.");
    }
  };

  const gotoBackTodo = () => {
    setCurrentScreen(ScreenTypes.FixPoorDietComponent3);
  };

  const { t } = useTranslation();

  const ProgressCount = useQuiz().ProgressCount;

  const { quizDetails, timer, setTimer, setEndTime } = useQuiz();

  useTimer(timer, quizDetails, setEndTime, setTimer);

  return (
    <PageCenter>
      <CenterCardContainer>
        <div>
          <HeaderContainer>
            <ProgressBar>
              {t(`Progress Day`)}
              <HighlightedText> : {ProgressCount}</HighlightedText>
            </ProgressBar>
            <QuizHeader timer={timer} />
          </HeaderContainer>
          <div>
            <Title>{t("3. Fix Poor diet")}</Title>
            <DirectionTitle>
              <HighlightedText>
                {t(
                  "Stop poor diet with zero or low TAC, and with high glycemic index and load:"
                )}
              </HighlightedText>
            </DirectionTitle>
          </div>
        </div>
        <TodoStyle>
          {todoList.map((todo, index) => (
             <SurveryContainer>
             <CheckBoxLabel key={index}>
               <CheckBoxButton key={index} onClick={() => handleCheckBox(index)}>
                 <Label>{t(todo.title)}</Label>
                 {checkedTodoes[index] ? "✔️" : ""}
               </CheckBoxButton>
             </CheckBoxLabel>
 
             <EvidenceContainer>
               <HighlightedText>{t(`${todo.evidence}`)}</HighlightedText>
             </EvidenceContainer>
             <LinkContainer>
               {todo.linkUrl.map((link, index) => (
                 <div key={index}>
                   {Object.entries(link).map(([key, value]) => (
                     <a key={key} href={value}>
                       {t(`${key}`)}
                     </a>
                   ))}
                 </div>
               ))}
             </LinkContainer>
           </SurveryContainer>
          ))}
        </TodoStyle>
        <ButtonContainer>
          <Button
            text={t("Back")}
            iconPosition="left"
            onClick={gotoBackTodo}
          />
          <Button
            text={t("Next")}
            iconPosition="right"
            onClick={gotoNextTodo}
            disabled={!checkedTodoes.every((isChecked) => isChecked)}
          />
        </ButtonContainer>
      </CenterCardContainer>
    </PageCenter>
  );
};

export default FixPoorDietComponent4;
