import { FC, MouseEvent, useTransition } from "react";
import {
  CenterCardContainer,
  HighlightedText,
  PageCenter,
} from "../../../styles/Global";
import { DirectionTitle } from "./recommendation5";
import MailChimp from "../../Subscription/MailChimp";
import Button from "../../ui/Button";
import { useQuiz } from "../../../context/QuizContext";
import { ScreenTypes } from "../../../types";
import { useTranslation } from "react-i18next";

const RecommendationScreen1: FC = () => {
  const { setCurrentScreen } = useQuiz();

  const goback = () => {
    setCurrentScreen(ScreenTypes.QuestionScreen);
  };

  const { t } = useTranslation();
  return (
    <PageCenter>
      <CenterCardContainer>
        <DirectionTitle>
          {t(
            "This program guarantees complete diabetes remission within a defined time frame."
          )}
          <HighlightedText>
            {t(
              "Subscribe to book your place on the waiting list of the early (invite-only-users) at the Remission Clinic platform"
            )}
            {' '}
            <a href="https://remissionclinic.com">RemissionClinic.Com</a>
          </HighlightedText>
        </DirectionTitle>
        <MailChimp></MailChimp>
        <Button
          text={t("Back To Program")}
          onClick={goback}
          style={{ marginTop: "1rem" }}
        />
      </CenterCardContainer>
    </PageCenter>
  );
};

export default RecommendationScreen1;
