import { FC, MouseEvent, useState } from "react";
import { CenterCardContainer, PageCenter } from "../../styles/Global";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import Button from "../ui/Button";
import { ScreenTypes } from "../../types";
import { useQuiz } from "../../context/QuizContext";
import { device } from "../../styles/BreakPoints";
const CheckBoxLabel = styled.label`
  /* Add styles for the checkbox label */
`;

const PrivacyContainer = styled.div`
  width: 30rem;
  display: flex;
  flex-direction: column;
  @media ${device.md} {
    width: 100%;
  }
`;
const Title = styled.h1`
  font-size: 2rem;
  font-weight: 500;
  margin-bottom: 1rem;
  text-align: center;
`;

const Description = styled.p`
  width: 35rem;
  font-size: 1.1rem;
  font-weight: 500;
  margin-bottom: 1rem;
  text-align: start;
  color: ${({ theme }) => theme.colors.themeColor};
  @media ${device.md} {
    width: 100%;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
`;
const TermsOfUse: FC = () => {
  const { t } = useTranslation();
  const { setCurrentScreen } = useQuiz();

  const gotoBackTodo = () => {
    setCurrentScreen(ScreenTypes.QuizDetailsScreen);
  };

  return (
    <PageCenter>
      <CenterCardContainer>
        <PrivacyContainer>
          <div>
            <Title>{t("Terms of Use")}</Title>
            <Description>
              {t(
                "Welcome to our diabetes nutrition and remission program. The following explains the website's privacy policy and the method of collecting and using personal data. Please read this policy carefully to understand how your privacy will be protected. By using the Diabetes Nutrition and Remission Program, you agree to the following Terms of Use: The content of this program, including text, images, videos and other materials, is for informational purposes only. The Program is not intended to provide medical advice or to replace seeking consultations with a physician or qualified healthcare professional. We assume no liability for the accuracy, completeness or usefulness of the information provided in the software. By using the Diabetes Diet and Remission Program, you agree to be bound by these Terms. If you do not agree to these Terms, please do not use the program. We reserve the right to change these terms and conditions at any time. It's best to check the conditions regularly. Your continued use of the program after any change will constitute your acceptance of the updated Terms. Use the program at your own risk. We will not be liable for any loss arising from the use of the program or reliance on the information provided therein."
              )}
            </Description>
          </div>
          <div>
            <Title>{t("Disclaimer")} </Title>
            <Description>
              {t(
                "The information provided in this diabetes remission nutritional program is intended for educational purposes and to offer general guidance. It should not replace professional medical advice, diagnosis, or treatment. Always consult with your healthcare provider or a qualified professional for personalized advice related to your health conditions or concerns. Do not disregard medical advice or delay seeking it based on information learned from this program. Reliance on the information provided here is at your discretion and understanding that it is not a substitute for professional healthcare guidance."
              )}
            </Description>
          </div>
          <Button
            text={t("Back")}
            onClick={gotoBackTodo}
            style={{ marginTop: "1rem", alignSelf: "center" }}
          />
        </PrivacyContainer>
      </CenterCardContainer>
    </PageCenter>
  );
};

export default TermsOfUse;
