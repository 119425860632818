import { FC, MouseEvent, useState } from "react";
import { CenterCardContainer, PageCenter } from "../../styles/Global";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import Button from "../ui/Button";
import { ScreenTypes } from "../../types";
import { useQuiz } from "../../context/QuizContext";
import { device } from "../../styles/BreakPoints";
const CheckBoxLabel = styled.label`
  /* Add styles for the checkbox label */
`;

const PrivacyContainer = styled.div`
  width: 30rem;
  display: flex;
  flex-direction: column;
  @media ${device.md} {
    width: 100%;
  }
`;
const Title = styled.h1`
  font-size: 2rem;
  font-weight: 500;
  margin-bottom: 1rem;
  text-align: center;
`;

const Description = styled.p`
  width: 35rem;
  font-size: 1.1rem;
  font-weight: 500;
  margin-bottom: 1rem;
  text-align: start;
  color: ${({ theme }) => theme.colors.themeColor};
  @media ${device.md} {
    width: 100%;
  }
`;

const PrivacyPolicy: FC = () => {
  const { t } = useTranslation();
  const { setCurrentScreen } = useQuiz();
  const [isChecked, setIsChecked] = useState(false);

  const gotoBackTodo = () => {
    setCurrentScreen(ScreenTypes.QuizDetailsScreen);
  };

  return (
    <PageCenter>
      <CenterCardContainer>
        <PrivacyContainer>
          <div>
            <Title>{t("Privacy Policy")}</Title>
            <Description>
              {t(
                "In our diabetes nutrition and remission program, we are committed to protecting your privacy. All personal information collected as part of this program, including name, email address and health information, will be used for the purpose of providing better services and improving your experience within the program. We do not sell, trade, or otherwise transfer your personal information to third parties. By using this program, you agree to the collection and use of information as described in this policy. Thank you for using our program and we hope you have an effective and unique experience in improving your health and remission from diabetes."
              )}
            </Description>
          </div>
          <Button
            text={t("Back")}
            onClick={gotoBackTodo}
            style={{ marginTop: "1rem", alignSelf: "center" }}
          />
        </PrivacyContainer>
      </CenterCardContainer>
    </PageCenter>
  );
};

export default PrivacyPolicy;
