import styled from "styled-components";

import { Next } from "../../config/icons";
import AppLogo from "../../assets/images/logo.svg";
import "../../i18n";
import backImage from "../../assets/icons/back.svg";

import {
  CenterCardContainer,
  HighlightedText,
  PageCenter,
} from "../../styles/Global";

import Button from "../ui/Button";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ScreenTypes } from "../../types";
import { useQuiz } from "../../context/QuizContext";

const AppTitle = styled.div`
  font-weight: 500;
  font-size: 2rem;
  color: ${({ theme }) => theme.colors.themeColor};
  @media (max-width: 768px) {
    font-size: 2rem;
    text-align: center;
  }
`;

const DetailTextContainer = styled.div`
  font-size: 20px;
  font-weight: 500;
  margin-top: 15px;
  margin-bottom: 40px;
  text-align: center;
  max-width: 500px;
`;

const DetailText = styled.h1`
  font-size: 20px;
  justify-content: start;
  font-weight: 500;
  margin-top: 15px;
`;
const LogoContainer = styled.div`
  height: auto; /* Adjust the height of the logo */
  width: auto;
  margin-bottom: 4rem;
  @media (max-width: 768px) {
    margin-bottom: 2rem;
  }
`;

const LogoImage = styled.img`
  width: 70px; /* Adjust the width of the logo */
  height: auto;
`;

const ButtonRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  width: 100%;
  padding: 0 20px;

  @media (max-width: 768px) {
    padding: 0;
  }
`;
const BackIcon = styled.img`
  width: 24px; /* Set the width of the icon */
  height: 24px; /* Set the height of the icon */
  margin-right: 8px; /* Add some margin on the right side */
  /* Add any other styles you want for the back icon */
`;

const BenefitContainer = styled.div`
  text-align: center;
  ul {
    display: inline-block;
    text-align: left;
    li {
      position: relative;
      padding-left: 25px;
      margin-bottom: 10px;

     
    
    }
  }
`;

const AgreementContainer = styled.p`
  margin-top: 3rem;
  text-wrap:wrap;
  text-align: center;
`;

const Header = styled.div`
  padding: 2rem 0 2rem 0;
`;

const QuizDetailsScreen = () => {
  const { setCurrentScreen } = useQuiz();

  const gotoTermsOfUse = () => {
    setCurrentScreen(ScreenTypes.TermsOfUseScreen);
  };
  const gotoprivacy = () => {
    setCurrentScreen(ScreenTypes.PrivacyPolicy);
  };

  const goToQuestionScreen = () => {
    setCurrentScreen(ScreenTypes.QuestionScreen);
  };
  const goBackQuestionScreen = () => {
    setCurrentScreen(ScreenTypes.LandingPageScreen);
  };

  const { t } = useTranslation();

  return (
    <PageCenter>
      <CenterCardContainer>
        <LogoContainer>
          <LogoImage src={AppLogo} alt="App Logo" />{" "}
        </LogoContainer>
        <AppTitle>
          <h3>{t("Diabetes Management")}</h3>
        </AppTitle>
        <DetailTextContainer>
          <DetailText>
            <HighlightedText>
              {t("Complete Remisssion and off Diabetes medications")}
            </HighlightedText>
          </DetailText>

          <Header>
            <h1>
              {t("2 Month Plan")}{" "}
              <HighlightedText>{t("Benefits")}</HighlightedText>
            </h1>
          </Header>

          <BenefitContainer>
            <ul>
              <li>&#x2713;{t("Fixing Wrong Medication")}</li>
              <li>&#x2713;{t("Prevent and Reduce Diabetes complications")}</li>
              <li>&#x2713;{t("Save on monthly medication costs")}</li>
              <li>&#x2713;{t("Correct diets")}</li>
            </ul>
          </BenefitContainer>
          <DetailText>
            {t(
              "Reclaim your health by following our program's precise guidlines."
            )}
          </DetailText>
        </DetailTextContainer>
        <ButtonRow>
          <Button
            text={t("Back")}
            iconPosition="left"
            onClick={goBackQuestionScreen}
          />
          <Button
            text={t("Start")}
            iconPosition="right"
            onClick={goToQuestionScreen}
          />
        </ButtonRow>
        <AgreementContainer>
          {t("By continuing, you agree to our")}{" "}
          <button onClick={gotoTermsOfUse} style={{ color: "blue" }}>
            {t("Terms of Use and Disclaimer")}
          </button>
          {" "}
          {t("and")} {" "}
          <button onClick={gotoprivacy} style={{ color: "blue" }}>
            {t("Privacy Policy")}
          </button>
          .
        </AgreementContainer>
      </CenterCardContainer>
    </PageCenter>
  );
};

export default QuizDetailsScreen;
