import styled from 'styled-components'

import { useQuiz } from '../../context/QuizContext'
import { quizTopics } from '../../data/quizTopics'
import { device } from '../../styles/BreakPoints'
import  AppLogo  from '../../assets/images/logo.svg'

import {
  CenterCardContainer,
  HighlightedText,
  PageCenter,
} from '../../styles/Global'
import { ScreenTypes } from '../../types'

import Button from '../ui/Button'
import { useTranslation } from 'react-i18next'

import '../../i18n'

const Heading = styled.h2`
  font-size: 32px;
  font-weight: 700;
  text-align: center;
`



const SelectButtonContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 60%;
  gap: 30px;
  margin-top: 40px;
  margin-bottom: 45px;
  @media ${device.md} {
    row-gap: 20px;
    column-gap: 20px;
    max-width: 100%;
  }
`

interface SelectButtonProps {
  active: boolean
  disabled?: boolean
}

const LogoContainer = styled.div`
  height: auto;
  width: auto;
  padding: 2px;
  margin-bottom: 3rem;
`;

const LogoImage = styled.img`
  width: 70px; /* Adjust the width of the logo */
  height: auto;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top:6rem;
  padding: 0 20px;
  @media ${device.md} {
    padding: 0 10px;
  }

`;

const AdvantageContainer = styled.ul`
  display: flex;
  flex-direction: column;
  font-size: 20px;
  align-items: start;

 

    li {
      position: relative;
      padding-left: 25px; /* Add padding for the icon */
      margin-bottom: 10px;
      &:before {
        content: "\\2713"; /* Unicode character for checkmark */
        position: absolute;
        left: 0;
        color: green; /* Change the color to green */
    }
  }
`
const Header = styled.h2`
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 20px;
  text-align: center;
  margin-top: 20px;
`



const QuizTopicsScreen: React.FC = () => {
  const {  setCurrentScreen } = useQuiz()

  const goToQuizDetailsScreen = () => {
    setCurrentScreen(ScreenTypes.QuizDetailsScreen)
  }
 const goToLandingScreen =()=>{
  setCurrentScreen(ScreenTypes.LandingPageScreen)
 }
  const {t} = useTranslation();

  

  return (
    <PageCenter >
      <CenterCardContainer>
      <LogoContainer>
          <LogoImage src={AppLogo} alt="App Logo" /> {/* Render AppLogo as an image */}
        </LogoContainer>
        <Heading>
          {t("WELCOME TO")} <HighlightedText> {t("Remission Clinic")}</HighlightedText>
        </Heading>
       
        
        <div>
      <Header>
        {t("What We do?")}
      </Header>
      <AdvantageContainer>
          <li>{t("Specialized Expertise")}</li>
          <li>{t("Personalized Treatment Plans")}</li>
          <li>{t("Education and Support")}</li>
          <li>{t("Focus on Long-Term Health")}</li>
      </AdvantageContainer>
    </div>
          
        <ButtonContainer>
        <Button text={t("Back")} onClick={goToLandingScreen}  />
        <Button text={t("Continue")} onClick={goToQuizDetailsScreen} />
        </ButtonContainer>


      </CenterCardContainer>
    </PageCenter>
  )
}

export default QuizTopicsScreen










