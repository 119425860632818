import styled from "styled-components";
import { device } from "../../../styles/BreakPoints";
import { FC } from "react";
import crashlowcarb from "../../../assets/images/lowcarb.jpg";
import { CenterCardContainer, PageCenter } from "../../../styles/Global";
import { DirectionTitle } from "./recommendation5";

import MailChimp from "../../Subscription/MailChimp";
import { themes } from "../../../styles/Theme";
import Button from "../../ui/Button";
import { useQuiz } from "../../../context/QuizContext";
import { ScreenTypes } from "../../../types";
import { useTranslation } from "react-i18next";

const ImageContainer = styled.div`
  width: 30rem;
  border-radius: 10rem;
  @media ${device.md} {
    width: 19rem;
  }
`;
const HighlightedTextLowCarb = styled.div`
  width: 30rem;
  color: ${({ theme }) => theme.colors.themeColor};
  text-align: center;
  @media ${device.md} {
    width: 19rem;
  }
`;

const RecommendationScreen4: FC = () => {
  const { setCurrentScreen } = useQuiz();

  const goback = () => {
    setCurrentScreen(ScreenTypes.FixPoorDietComponent);
  };
  const { t } = useTranslation();
  return (
    <PageCenter>
      <CenterCardContainer>
        <ImageContainer>
          <img
            src={crashlowcarb}
            alt="Description"
            style={{ borderRadius: "10px" }}
          />
        </ImageContainer>
        <p>
          {t("Follow")}{" "}
          <a
            href="https://www.stylecraze.com/articles/how-to-lose-weight-in-one-week-by-crash-dieting/"
            style={{ color: "green" }}
          >
            {t("Crash diet")}
          </a>{" "}
          +{" "}
          <a
            href="https://www.healthline.com/nutrition/low-carb-diet-meal-plan-and-menu"
            style={{ color: "green" }}
          >
            {" "}
            {t("Low carb diet")}{" "}
          </a>{" "}
          {t("until you lose 15-20% of your weight fast.")}
        </p>

        <DirectionTitle>
          {t("Subscribe to get the latest free weekly Diabetes Remission tips")}
        </DirectionTitle>

        <MailChimp></MailChimp>
        <Button
          text={t("Back To Program")}
          onClick={goback}
          style={{ marginTop: "1rem" }}
        />
      </CenterCardContainer>
    </PageCenter>
  );
};

export default RecommendationScreen4;
