import styled from "styled-components";
import { FC, MouseEvent } from "react";
import { device } from "../../styles/BreakPoints";
import doctorImage from "../../assets/images/doctor.jpg"; // Import your doctor image here
import logo from "../../assets/images/logo.svg"; // Import your logo here
import { useQuiz } from "../../context/QuizContext";
import { ScreenTypes } from "../../types";
import Button from "../ui/Button";
import { useTranslation } from "react-i18next";
import "../../i18n";
import { HighlightedText } from "../../styles/Global";

const LandingPageContainer = styled.div`
  width: 100%;
  min-height: 500px;
  background: ${({ theme }) => theme.colors.background};
  display: grid;
  grid-template-columns: 1fr; /* Single column for default */
  padding: 30px;
  box-sizing: border-box;
  gap: 20px;

  @media ${device.md} {
    grid-template-columns: 1fr;
    padding: 50px;
  }
`;

const Logo = styled.img`
  height: 60px; /* Adjust the height of the logo */
  width: auto;
  padding: 2px;
  margin: 10px;
`;

const Description = styled.p`
  width: 40rem;
  margin-bottom: 20px; /* Spacing below description */
  text-align: start;
  font-size: 3rem;
  @media ${device.md} {
    width: 100%;
    font-size: 2rem;
  } /* Larger text size */
`;

const Image = styled.img`
  max-width: 100%;
  height: auto;
  border-radius: 8px; /* Optional: Add border radius to the image */
`;

const Footer = styled.footer`
  background-color: ${({ theme }) => theme.colors.background};
  color: ${({ theme }) => theme.colors.themeColor};
  padding: 20px;
  text-align: center;
`;
const DescriptionSub = styled.p`
  width: 40rem;
  margin-bottom: 20px; /* Spacing below description */
  text-align: start;
  font-size: 1.2rem;
  font-weight: 400;
  font-family: Open Sans, monospace;
  @media ${device.md} {
    width: 100%;
    font-size: 1rem;
  } /* Larger text size */
`;

const CopyrightText = styled.p`
  font-size: 14px;
`;
const ServiceContainer = styled.div`
  margin-top: 5rem;
  align-self: center;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const CardContainer = styled.div`
  align-self: center;
  width: 60rem;
  background-color: ${({ theme }) => theme.colors.infoBackground};
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
  @media ${device.md} {
    width: 100%;
  } /* Larger text size */
`;

const ServiceDescription = styled.p`
  margin-bottom: 20px; /* Spacing below description */
  text-align: start;
  font-size: 1rem;
  font-weight: 400;
  font-family: Open Sans, monospace;
  @media ${device.md} {
    font-size: 1rem;
  } /* Larger text size */
`;

const Title = styled.h1`
  padding: 0.5rem;
  width: 40rem;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.themeColor};
  @media ${device.md} {
    width: 100%;
  }
`;
const Service = styled.h1`
  margin-top: 1rem;
  margin-bottom: 1rem; /* Spacing below description */
  text-align: center;
  color: ${({ theme }) => theme.colors.themeColor};
  font-size: 2rem;
  font-weight: 700;
`;

const IntroContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  @media ${device.md} {
    grid-template-columns: 1fr;
  }
`;

const WelcomeContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ImageContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  shape-outside: circle(50%); /* Define a circle around the image */
  shadow: 0 0 0 2000px rgba(0, 0, 0, 0.5); /* Create a shadow behind the image */
  /* Define styles for the image within the container */
  img {
    max-width: 100%;
    max-height: 100%;
    border: 1px solid #ccc; /* Add a gray border */

    border-radius: 8px; /* Optional: Add border radius to the image */
    object-fit: cover; /* Ensure the image covers the container */
  }
`;



export const StyledListItem = styled.li`
  list-style-type: none;
  margin-bottom: 8px;
  padding: 0.5rem;
  &:before {
    content: "\\27A2"; /* Use the escape sequence for the Unicode character */
    margin-right: 5px; /* Adjust the spacing between the icon and text */
    color: ${({ theme }) => theme.colors.themeColor};
  }
`;

const ContactButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end; /* Align the button to the right */
  margin-top: 1rem; /* Adjust margin as needed */
`;

const LandingPageScreen: FC = () => {
  const { setCurrentScreen } = useQuiz();
  const { t } = useTranslation();

  const goToQuizDetailsScreen = () => {
    setCurrentScreen(ScreenTypes.QuizDetailsScreen);
  };

  const openGmail = () => {
    const toEmail = "contact@remissionclinic.com";
    const subject = encodeURIComponent("Inquiry");
    const body = encodeURIComponent("Type your message here.");
  
    const gmailUrl = `https://mail.google.com/mail/?view=cm&fs=1&to=${toEmail}&su=${subject}&body=${body}`;
  
    window.open(gmailUrl, "_blank");
  };
  
  
  return (
    <div>
      <Logo src={logo} />
      <LandingPageContainer>
        <IntroContainer>
          <WelcomeContainer>
            <Description>
              <HighlightedText>
                {t("Welcome to the evidence-based diabetes remission program.")}
              </HighlightedText>
            </Description>
            <DescriptionSub>
              {t(
                "Save on Consultation Costs: Follow Expert & Evidence-Based Nutritional Guidance Independently."
              )}
            </DescriptionSub>
            <DescriptionSub>
              <HighlightedText>
                {t(
                  "Discover a tailored nutrition plan backed by science, guiding you toward complete diabetes remission and freedom from medications in just two months."
                )}
              </HighlightedText>
            </DescriptionSub>
            <Button
              text={t("Get Started")}
              onClick={goToQuizDetailsScreen}
              bold
              style={{ marginTop: "1rem", marginBottom: "1rem" }}
            />
          </WelcomeContainer>

          <ImageContainer>
            <Image src={doctorImage} alt="Doctor" className="doctor-image" />
          </ImageContainer>
        </IntroContainer>

        <ServiceContainer>
          <Service>{t("What We Do?")}</Service>
          <CardContainer>
            <Title>{t("1. Medication-Free Lifestyle")}</Title>
            <ServiceDescription>
              {t(
                "Achieve diabetes remission, freeing yourself from the need for medications while preventing potential complications."
              )}
            </ServiceDescription>
          </CardContainer>
          <CardContainer>
            <Title>{t("2. Health & Financial Security")}</Title>
            <ServiceDescription>
              {t(
                "Prevent diabetes-related complications, saving on monthly medication costs and potential healthcare expenses."
              )}
            </ServiceDescription>
          </CardContainer>
          <CardContainer>
            <Title>{t("3. Safe, flexible & Personalized Experience")} </Title>
            <ServiceDescription>
              {t(
                "Prioritizing your safety, this program offers a personalized experience with nutritional instructions at your convenience. With the freedom to continue or pause based on your commitment level, it's your empowered journey towards improved health."
              )}
            </ServiceDescription>
          </CardContainer>
          <CardContainer>
            <Title>{t("4. Independent Journey")} </Title>
            <ServiceDescription>
              {" "}
              {t(
                "Navigate freely through nutritional instructions, Assistance available on request."
              )}
            </ServiceDescription>
          </CardContainer>
          <CardContainer>
            <Title>{t("5. Integrating Evidence & Clinical Expertise")}</Title>
            <ServiceDescription>
              {" "}
              {t(
                "Our platform combines trusted scientific validation with the practical wisdom of medical experts, ensuring a straightforward path to diabetes remission and enhanced health."
              )}
            </ServiceDescription>
          </CardContainer>
          <CardContainer>
            <Title>{t("6. Weight Management & Wellness")} </Title>
            <ServiceDescription>
              {t(
                "Experience potential weight loss (if needed) while embracing a holistic wellness approach for lasting health benefits."
              )}
            </ServiceDescription>
          </CardContainer>
        </ServiceContainer>

        <ServiceContainer>
          <Service>{t("Our Story Through the Years")}</Service>
          <CardContainer>
            <Title>{t("Year 2020")} </Title>
            <ServiceDescription>
              <ul>
                <StyledListItem>
                  {t(
                    "Founding Vision: Dr. Own, a medical doctor passionate about clinical nutrition and healthy lifestyles, begins the journey to create an accessible and affordable clinical nutrition platform."
                  )}
                </StyledListItem>
                <StyledListItem>
                  {t(
                    "Startup Commencement: Leads a team of engineers, life sciences researchers, and doctors to develop technology aiming to provide personalized nutrition guidance, akin to having a personal nutritionist."
                  )}
                </StyledListItem>
              </ul>
            </ServiceDescription>
          </CardContainer>

          <CardContainer>
            <Title>{t("Year 2021")} </Title>
            <ServiceDescription>
              <ul>
                <StyledListItem>
                  {t(
                    "Pioneering Program: Launches the inaugural diabetes remission program, born out of years of clinical experience and in-depth exploration of medical literature. Focuses on achieving complete diabetes remission and preventing complications through personalized nutritional instructions."
                  )}
                </StyledListItem>
                <StyledListItem>
                  {t(
                    "Root Cause Treatment: Initiates the mission to address the root causes of chronic diseases, starting with a focus on type 2 diabetes." 
                  )}
                </StyledListItem>
              </ul>
            </ServiceDescription>
          </CardContainer>

          <CardContainer>
            <Title>{t("Current Stage")} </Title>
            <ServiceDescription>
              <ul>
                <StyledListItem>
                  {t(
                    "Global Impact Goal: Aims to secure diabetes remission for one billion type 2 diabetes patients worldwide through ongoing technological development."
                  )}
                </StyledListItem>
                <StyledListItem>
                  {t(
                    "Intends to extend the same approach to various chronic diseases impacted by lifestyle and nutrition factors."
                  )}
                </StyledListItem>
              </ul>
            </ServiceDescription>
          </CardContainer>
          <CardContainer>
            <Title>{t("Future Vision")} </Title>
            <ServiceDescription>
              <ul>
                <StyledListItem>
                  {t(
                    "Personal Motivation: Driven by a personal quest stemming from a desire to find a lasting solution for type 2 diabetes, initially inspired by a family member's struggle with the condition." 
                  )}
                </StyledListItem>
                <StyledListItem>
                  {t(
                    "Evolutionary Journey: Started with one-on-one patient care, expanded to online consultations, and now strives to make disease management accessible and affordable through technology."
                  )}
                </StyledListItem>
                <StyledListItem>
                  {t(
                    "Development Phases: Progressed from initial foundations in 2020, online consultations in 2021, to the launch of the first version of a self-guided diabetes remission clinic platform. The focus remains on providing affordable access to nutritional instructions for disease remission."
                  )}
                </StyledListItem>
              </ul>
            </ServiceDescription>
          </CardContainer>

          <CardContainer>
            <Title>{t("Upcoming Stages")} </Title>
            <ServiceDescription>
              <ul>
                <StyledListItem>
                  {t(
                    "AI-Powered Solutions: The forthcoming phase involves creating the most reliable AI model for clinical nutrition and health. This AI aims to revolutionize disease treatment and prevention through lifestyle and nutrition guidance."
                  )}
                </StyledListItem>
              </ul>
            </ServiceDescription>
          </CardContainer>
        </ServiceContainer>

        <ContactButtonContainer>
            <Button text={t('Contact Us')} onClick={openGmail}/>
          </ContactButtonContainer>
      </LandingPageContainer>

      <Footer>
        <CopyrightText>
          &copy; {t("2023 Remission Clinic. All Rights Reserved.")}
        </CopyrightText>
      </Footer>
    </div>
  );
};

export default LandingPageScreen;


