import { FC, useEffect, useState } from 'react'
import styled from 'styled-components'

import { Next } from '../../config/icons'
import { useQuiz } from '../../context/QuizContext'
import { useTimer } from '../../hooks'
import { device } from '../../styles/BreakPoints'
import { PageCenter } from '../../styles/Global'
import { ScreenTypes } from '../../types'

import Button from '../ui/Button'
import Question from './Question'
import Back from "../../assets/icons/back.svg"
import '../../i18n'
import { useTranslation } from 'react-i18next'

const QuizContainer = styled.div<{ selectedAnswer: boolean }>`
  width: 900px;
  min-height: 500px;
  background: ${({ theme }) => theme.colors.cardBackground};
  border-radius: 4px;
  padding: 30px 60px 80px 60px;
  margin-bottom: 70px;
  position: relative;
  @media ${device.md} {
    width: 100%;
    padding: 15px 15px 80px 15px;
  }
  button {
    span {
      svg {
        path {
          fill: ${({ selectedAnswer, theme }) =>
            selectedAnswer ? `${theme.colors.buttonText}` : `${theme.colors.darkGray}`};
        }
      }
    }
  }
`

const LogoContainer = styled.div`
  margin-top: 50px;
  margin-bottom: 50px;
  @media ${device.md} {
    margin-top: 10px;
    margin-bottom: 20px;
    svg {
      width: 185px;
      height: 80px;
    }
  }
`

const BackIcon = styled.img`
  width: 20px; /* Adjust the width of the icon */
  height: 20px; /* Adjust the height of the icon */
  /* Add any additional styles here */
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const QuestionScreen: FC = () => {
  const [activeQuestion, setActiveQuestion] = useState<number>(0)
  const [selectedAnswer, setSelectedAnswer] = useState<string[]>([])
  const [showTimerModal, setShowTimerModal] = useState<boolean>(false)
  const [showResultModal, setShowResultModal] = useState<boolean>(false)
  const [userSurveyInfo, setUserSurveyInfo] = useState<string[]>([])
  const {setCurrentScreen,setChoosenPath,messagePath,setMessagePath} = useQuiz();
  

  const {
    questions,
    quizDetails,
    timer,
    setTimer,
    setEndTime,
  } = useQuiz()


  const {t} = useTranslation();

  const currentQuestion = questions[activeQuestion]

  const { message,title,question, type, choices, code, image } = currentQuestion

  const onClickNext = () => {


    if (activeQuestion !== questions.length - 1) {

      if (activeQuestion === 0) {


        if (
          selectedAnswer[selectedAnswer.length - 1] ===
          t("I'm at prediabetes, or I need diabetes prevention only")
        ) {
          setActiveQuestion(1)
          setMessagePath(1)

        } 
        
        else if (
          selectedAnswer[selectedAnswer.length - 1] === t('I\'ve diabetes type 1')
        ) {

         
            setCurrentScreen(ScreenTypes.RecommendationScreen2);
          
          return ;
        } 
        
        else if (selectedAnswer[selectedAnswer.length - 1] === t("I've diabetes type 2")) {
          setActiveQuestion(3)
        } 
        
        else if (selectedAnswer[selectedAnswer.length - 1] === t('I don\'t know')) {
          
          setCurrentScreen(ScreenTypes.RecommendationScreen5)
          return;
        }
      }
      
      else if (activeQuestion === 1) {
        if (
          selectedAnswer[selectedAnswer.length - 1] ===
          t('Easy mood (80% Complete Remission guaranteed,100% Partial Remission guaranteed): No need for frequent blood sugar and body weight measurements throughout the 60-day program.')
        ) {
          setActiveQuestion(2)
        } else if (
          selectedAnswer[selectedAnswer.length - 1] ===
          t("Advanced mood (100% Complete Remission guaranteed): Frequent blood sugar and body weight measurements are required throughout the 60-day program. ")
        ) {
          setCurrentScreen(ScreenTypes.RecommendationScreen1)
          return;
        }
      } else if (activeQuestion === 2) {
        if (
          selectedAnswer[selectedAnswer.length - 1] === t('Yes, I’m obese or overweight')
        ) {

          setChoosenPath(1)
          
          setCurrentScreen(ScreenTypes.HowToUseScreen)
        
        
          return ;
        } else if (
          selectedAnswer[selectedAnswer.length - 1] === t('No, I’m not obese or overweight')
        ) {
            setChoosenPath(2);
            setCurrentScreen(ScreenTypes.HowToUseScreen)
  
          return;
        }
      } else if (activeQuestion === 3) {
        if (selectedAnswer[selectedAnswer.length - 1] === t('No')) {
          setActiveQuestion(1)
        } else if (selectedAnswer[selectedAnswer.length - 1] === t('Yes')) {
          setActiveQuestion(4)
        }
      } else if (activeQuestion === 4) {
        if (selectedAnswer[selectedAnswer.length - 1] === t('Yes')) {
          setCurrentScreen(ScreenTypes.RecommendationScreen3)
          return;
        } else if (
          selectedAnswer[selectedAnswer.length - 1] === t('No I do not take insulin')
        ) {
          setActiveQuestion(5)
        }
      }
    } else if (activeQuestion === 5) {
      if (selectedAnswer[selectedAnswer.length - 1] === t('I take Metformin only')) {
        setActiveQuestion(1)
        setMessagePath(2);

      } else if (
        selectedAnswer[selectedAnswer.length - 1] ===
        t('I take other sugar-lowering or mixed hypoglycemic drugs')
      ) {
       setCurrentScreen(ScreenTypes.RecommendationScreen6)
        return;
      }
    } else {
      const timeTaken = quizDetails.totalTime - timer
      setEndTime(timeTaken)
      setShowResultModal(true)
    }
    setSelectedAnswer([])

  
  }

  const onClickBack = () => {
    if (activeQuestion !== 0) {
      setActiveQuestion((prevActiveQuestion) => prevActiveQuestion - 1)
    }
    else{
      setCurrentScreen(ScreenTypes.QuizDetailsScreen)
    }
  }



  const handleAnswerSelection = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target
    userSurveyInfo.push(name)
   
    if (type === 'MAQs') {
      if (selectedAnswer.includes(name)) {
        setSelectedAnswer((prevSelectedAnswer) =>
          prevSelectedAnswer.filter((element) => element !== name)
        )
      } else {
        setSelectedAnswer((prevSelectedAnswer) => [...prevSelectedAnswer, name])
      }
    }

    if (type === 'MCQs' || type === 'boolean') {
      if (checked) {
        setSelectedAnswer([name])
      }
    }
  }



  useEffect(() => {
    if (showTimerModal || showResultModal) {
      document.body.style.overflow = 'hidden'
    }
  }, [showTimerModal, showResultModal])

  useTimer(timer, quizDetails, setEndTime, setTimer,)

  let currMessage = 'Great! You can start the Remission Program now!'
  if(messagePath ===2){
    currMessage = "Great! You can start the Remission Program now! Stop Metformin when blood sugar stays normal for around 30 days."
  }
 

  return (
    <PageCenter>
      <LogoContainer>
      
      </LogoContainer>
      <QuizContainer selectedAnswer={selectedAnswer.length > 0}>
        
        
        <Question
          message={message? currMessage: message}
          title = {title}
          question={question}
          code={code}
          image={image}
          choices={choices}
          type={type}
          handleAnswerSelection={handleAnswerSelection}
          selectedAnswer={selectedAnswer}
        />
        <ButtonContainer>
         
        <Button
          text={t("Back")}
          onClick={onClickBack}
          iconPosition="left"
          disabled={false}
        />
           <Button
            text={t("Next")}
            onClick={onClickNext}
            iconPosition="right"
            disabled={selectedAnswer.length === 0}
          />
        </ButtonContainer>
      </QuizContainer>
    </PageCenter>
  )
}

export default QuestionScreen