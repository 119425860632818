import React, { FC, useState } from "react";
import {
  CenterCardContainer,
  HighlightedText,
  PageCenter,
} from "../../styles/Global";
import Button from "../ui/Button";
import { Next, StartIcon } from "../../config/icons";
import styled from "styled-components";
import { device } from "../../styles/BreakPoints";
import { useTranslation } from "react-i18next";
import "../../i18n";
import { useQuiz } from "../../context/QuizContext";
import { ScreenTypes } from "../../types";
import QuizHeader from "../QuestionScreen/QuizHeader";
import { useTimer } from "../../hooks";
import backImage from "../../assets/icons/back.svg";
import {
  ButtonContainer,
  CheckBoxButton,
  EvidenceContainer,
  LinkContainer,
  ProgressBar,
  SurveryContainer,
  Todo,
} from "./FixPoorDiet";

const CheckBoxLabel = styled.label`
  padding: 18px;
  display: flex;
  align-items: center; /* Align items vertically */
  cursor: pointer;

  @media ${device.md} {
    padding: 14px;
  }

  input {
    margin-right: 10px; /* Add space between checkbox and text */
  }
`;

const TodoStyle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Align todo items to the left */
  margin-bottom: 40px;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const BackIcon = styled.img`
  width: 24px; /* Set the width of the icon */
  height: 24px; /* Set the height of the icon */
  margin-right: 8px; /* Add some margin on the right side */
  /* Add any other styles you want for the back icon */
`;

export const Label = styled.p`
  font-weight: 500;
  font-size: 16px;
  text-align: left; /* Center the label text */
`;

const Title = styled.h1`
  width: 35rem;
  text-align: center;
  padding: 1rem;
  @media ${device.md} {
    font-size: 1.4rem;
    width: 20rem;
  }
`;

const FixBadEatingPatternComponent: FC = () => {
  const todoList: Todo[] = [
    {
      title:
        "Easy intermittent fasting: Skip the breakfast meal (only drink coffee, tea, water, and herbal tea).",
      evidence: "",
      linkUrl: [],
    },
    {
      title:
        "When started, the first meal is strongly preferred to be fresh nutrient-rich (High TAC) fruits and vegetables.",
      evidence: "",
      linkUrl: [
        {
          "top-health-benefits-containing-Fruit-and-Fruit-Juices": "https://www.antioxida.com/categories/top-health-benefits-containing-Fruit-and-Fruit-Juices?Pid=17",
        },
      ],
    },
    {
      title: "Stop large separate meals, Eat small frequent meals.",
      evidence: "",
      linkUrl: [],
    },
    {
      title:
        "Eat healthy snacks rich in vitamins, healthy fats, and antioxidant compounds (those are rich TAC snacks). Focus on items that has strong scientific evidence and proven therapeutic/preventive potential in diabetes. such as Pure dark chocolate, Dried pomegranate, Dried blueberries, Dried Plums, Dried apricots, Almonds with pellicle, Walnuts with pellicle, Raspberry.",
      evidence: "",
      linkUrl: [
        {"Dried-pomegranate-evidence-based-health-benefits":"https://www.antioxida.com/Dried-pomegranate-evidence-based-health-benefits-review-up-to-date/product-id/1028"},
        {
         "Dried-apricots-evidence-based-health-benefits" :"https://www.antioxida.com/Dried-apricots-evidence-based-health-benefits-review-up-to-date/product-id/1021"
        },
        {
          "Walnuts-with-pellicle-evidence-based-health-benefits":"https://www.antioxida.com/Walnuts-with-pellicle-evidence-based-health-benefits-review-up-to-date/product-id/1374"
        },
        {
          "Raspberry-evidence-based-health-benefits":"https://www.antioxida.com/Raspberry-evidence-based-health-benefits-review-up-to-date/product-id/169"
        }
    ],
    },
  ];

  const [checkedTodoes, setCheckedTodoes] = useState<boolean[]>(
    Array(todoList.length).fill(false)
  );

  const handleCheckBox = (index: number) => {
    const updatedCheckedTodoes = [...checkedTodoes];
    updatedCheckedTodoes[index] = !updatedCheckedTodoes[index];
    setCheckedTodoes(updatedCheckedTodoes);
  };

  const { setCurrentScreen } = useQuiz();

  const gotoNextTodo = () => {
    const allChecked = checkedTodoes.every((isChecked) => isChecked);
    if (allChecked) {
      setCurrentScreen(ScreenTypes.FixProteinIntakeComponent);
    } else {
    }
  };
  const gotoBackTodo = () => {
    setCurrentScreen(ScreenTypes.FixPoorDietComponent);
  };
  const { t } = useTranslation();
  const ProgressCount = useQuiz().ProgressCount;

  const { quizDetails, timer, setTimer, setEndTime } = useQuiz();

  useTimer(timer, quizDetails, setEndTime, setTimer);

  return (
    <PageCenter>
      <CenterCardContainer>
        <div>
          <HeaderContainer>
            <ProgressBar>
              {t(`Progress Day`)}
              <HighlightedText> : {ProgressCount}</HighlightedText>
            </ProgressBar>
            <QuizHeader timer={timer} />
          </HeaderContainer>
          <div>
            <Title>{t("4. Fixing Bad eating pattern")}</Title>
          </div>
        </div>
        <TodoStyle>
          {todoList.map((todo, index) => (
            <SurveryContainer>
              <CheckBoxLabel key={index}>
                <CheckBoxButton
                  key={index}
                  onClick={() => handleCheckBox(index)}
                >
                  <Label>{t(todo.title)}</Label>
                  {checkedTodoes[index] ? "✔️" : ""}
                </CheckBoxButton>
              </CheckBoxLabel>
              <EvidenceContainer>
                <HighlightedText>{t(`${todo.evidence}`)}</HighlightedText>
              </EvidenceContainer>
              <LinkContainer>
                {todo.linkUrl.map((link, index) => (
                  <div key={index}>
                    {Object.entries(link).map(([key, value]) => (
                      <a key={key} href={value}>
                        {t(`${key}`)}
                      </a>
                    ))}
                  </div>
                ))}
              </LinkContainer>
            </SurveryContainer>
          ))}
        </TodoStyle>
        <ButtonContainer>
          <Button
            text={t("Back")}
            iconPosition="left"
            onClick={gotoBackTodo}
          />
          <Button
            text={t("Next")}
            iconPosition="right"
            onClick={gotoNextTodo}
            disabled={!checkedTodoes.every((isChecked) => isChecked)}
          />
        </ButtonContainer>
      </CenterCardContainer>
    </PageCenter>
  );
};

export default FixBadEatingPatternComponent;
