import { createContext, useContext, Dispatch, SetStateAction, ReactNode, useState, useEffect } from 'react';
import { TFunction } from 'i18next';
import { QuizContextTypes, Result, ScreenTypes } from '../types';
import { QuizContext, initialState } from './QuizContext';
import { quiz } from '../data/QuizQuestions';

type QuizProviderProps = {
  children: ReactNode;
};

const QuizProvider = ({ children }: QuizProviderProps) => {
  const [timer, setTimer] = useState<number>(initialState.timer);
  const [endTime, setEndTime] = useState<number>(initialState.endTime);
  const [quizTopic, setQuizTopic] = useState<string>(initialState.quizTopic);
  const [result, setResult] = useState<Result[]>(initialState.result);
  const [currentScreen, setCurrentScreen] = useState<ScreenTypes>(initialState.currentScreen);
  const [ProgressCount,setProgressCount] = useState<number>(initialState.ProgressCount);
  const [questions, setQuestions] = useState(quiz[initialState.quizTopic].questions);
  const [currTime,setCurrTime] =  useState<string>(initialState.currTime);
  const [choosenPath,setChoosenPath] =  useState<number>(initialState.choosenPath);
  const [messagePath,setMessagePath] =  useState<number>(initialState.messagePath);
  



  const {
    questions: quizQuestions,
    totalTime,
    totalScore,
  } = quiz[quizTopic];

  const selectQuizTopic = (topic: string) => {
    setQuizTopic(topic);
  };

  useEffect(() => {
    setTimer(totalTime);
    setQuestions(quizQuestions);
  }, [quizTopic]);

  const quizDetails = {
    totalScore,
    totalTime,
    selectedQuizTopic: quizTopic,
  };

  const quizContextValue: QuizContextTypes = {
    messagePath,
    setMessagePath,
    choosenPath,
    setChoosenPath,
    currTime,
    setCurrTime,
    ProgressCount,
    setProgressCount,
    currentScreen,
    setCurrentScreen,
    quizTopic,
    selectQuizTopic,
    questions,
    setQuestions,
    result,
    setResult,
    quizDetails,
    timer,
    setTimer,
    endTime,
    setEndTime,
  };

  return <QuizContext.Provider value={quizContextValue}>{children}</QuizContext.Provider>;
};

export default QuizProvider;
