// import { generalKnowledge } from './generalKnowledge'
// import { javascript } from './javascript'
// import { python } from './python'
import {DiabetesManagement } from './diabetesManagement'

// Question Types
// 1. MCQs | Multiple Choice | single
// 2. boolean | true/false | single
// 3. MAQs | Multiple Answers | multiple

type Choice =string
type CorrectAnswers = string[]

export type Question = {
  question: string
  title: string
  message: string
  choices: Choice[]
  type: 'MCQs' | 'MAQs' | 'boolean'
  code?: string
  image?: string
  next?: number
}

export type Topic = {
  topic: string
  level: string
  totalScore: number
  totalTime: number
  questions: Question[]
}

export const quiz: Record<string, Topic> = {
  
  DiabetesManagement: DiabetesManagement,
  
}
