import styled from "styled-components";
import { device } from "../../../styles/BreakPoints";
import { FC } from "react";
import {
  CenterCardContainer,
  HighlightedText,
  PageCenter,
} from "../../../styles/Global";
import MailChip from "../../Subscription/MailChimp";
import Button from "../../ui/Button";
import { useQuiz } from "../../../context/QuizContext";
import { ScreenTypes } from "../../../types";
import { useTranslation } from "react-i18next";

export const DirectionTitle = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 30rem;
  text-align: start;
  padding: 1rem;
  font-size: 1.3rem;
  font-weight: 700;
  @media ${device.md} {
    font-size: 1.3rem;
    width: 20rem;
  }
`;

export const FormContainer = styled.form`
  display: flex;
  justify-content: space-between;
  padding: 2rem;
  @media ${device.md} {
    padding: 0.1rem;
  }
`;

export const StyledInput = styled.input`
  background: white;
  width: 100%;
  padding: 1rem;
  border: 0.5px solid pink;
  border-radius: 0.4rem;
  margin-right: 0.5rem;
  @media ${device.md} {
    margin-right: 0.3rem;
  }
`;

const RecommendationScreen5: FC = () => {
  const { setCurrentScreen } = useQuiz();
  const goback = () => {
    setCurrentScreen(ScreenTypes.QuestionScreen);
  };
  const { t } = useTranslation();

  return (
    <PageCenter>
      <CenterCardContainer>
        <DirectionTitle>
          {t(
            "Get back to your doctor to ask about your diabetes type, once defined, you can continue the Remission Program."
          )}
          <HighlightedText>
            {t(
              "Subscribe to get the latest free weekly Diabetes Remission tips"
            )}
          </HighlightedText>
        </DirectionTitle>
        <MailChip></MailChip>

        <Button
          text={t("Back To Program")}
          onClick={goback}
          style={{ marginTop: "1rem" }}
        />
      </CenterCardContainer>
    </PageCenter>
  );
};

export default RecommendationScreen5;
