import React, { FC, useEffect } from 'react'; // Import useEffect
import styled from 'styled-components';
import Button from '../ui/Button';
import { useQuiz } from '../../context/QuizContext';
import { ScreenTypes } from '../../types';
import { CenterCardContainer, PageCenter } from '../../styles/Global';
import ReactPixel from 'react-facebook-pixel';

const ThankYouContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: Arial, sans-serif;
`;

const ThankYouMessage = styled.h1`
  font-size: 2rem;
  margin-bottom: 20px;
  text-align: center;
`;

const ThankYouPage: FC = () => {
  const { setCurrentScreen } = useQuiz();
  const goBack = () => {
    setCurrentScreen(ScreenTypes.FixPoorDietComponent);
  };

  useEffect(() => {
    // Manually send a page view event to the Facebook Pixel when the component mounts
    ReactPixel.init('1113805359512132');
    ReactPixel.track('PageView');
  }, []); // Empty dependency array means this effect runs once after mount

  return (
    <PageCenter>
      <CenterCardContainer>
        <ThankYouContainer>
          <ThankYouMessage>Thank You for Subscribing!</ThankYouMessage>
          <Button
            text="Continue your Remission"
            style={{ marginTop: '1rem', padding: '0.4rem' }}
            onClick={goBack}
          />
        </ThankYouContainer>
      </CenterCardContainer>
    </PageCenter>
  );
};

export default ThankYouPage;
