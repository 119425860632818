import { useEffect } from 'react'

import { useQuiz } from '../../context/QuizContext'
import { ScreenTypes } from '../../types'

import QuestionScreen from '../QuestionScreen'
import QuizDetailsScreen from '../QuizDetailsScreen'
import QuizTopicsScreen from '../QuizTopicsScreen'
// import ResultScreen from '../ResultScreen'
import SplashScreen from '../SplashScreen'
import RecommendationScreen from '../QuestionScreen/Recommendation'
import LandingPageScreen from '../LandingPage'
import RecommendationScreen1 from '../QuestionScreen/Recommendation/recommendation1'
import RecommendationScreen2 from '../QuestionScreen/Recommendation/recommendation2'
import RecommendationScreen4 from '../QuestionScreen/Recommendation/recommendation4'
import RecommendationScreen5 from '../QuestionScreen/Recommendation/recommendation5'
import RecommendationScreen6 from '../QuestionScreen/Recommendation/recommendation6'
import RecommendationScreen3 from '../QuestionScreen/Recommendation/recommendation3'
import StripeContainerScreen from '../Payement/stripeContainer';
import FixPoorDietComponent from '../TodoComponent/FixPoorDiet'
import FixProteinIntakeComponent from '../TodoComponent/FixProteinIntake'
import FixBodyComponent from '../TodoComponent/FixBody'
import FixBadEatingPatternComponent from '../TodoComponent/FixBadEatingPattern'
import FixStressComponent from '../TodoComponent/FixStress'
import '../../i18n'
import GetMedicalTreatmentComponent from '../TodoComponent/GetMedicalTreatment'
import FixPoorDietComponent2 from '../TodoComponent/FixPoorDiet2'
import FixPoorDietComponent3 from '../TodoComponent/FixPoorDiet3'
import FixPoorDietComponent4 from '../TodoComponent/FixPoorDiet4'
import FixPoorDietComponent5 from '../TodoComponent/FixPoorDiet5'
import LanguageSelector from '../Localizations/languageSelector'
import PrivacyPolicy from '../Policy/privacy'
import HowToUseScreen from '../TodoComponent/HowToUse'
import HowToUse from '../TodoComponent/HowToUse'
import TermsOfUse from '../Policy/TermsOfUse'
import { CheckoutForm } from '../Payement/paymentForm'
import ThankYouPage from '../Payement/thankyou'
function Main() {
  const { currentScreen, setCurrentScreen } = useQuiz()
  

  useEffect(() => {
    setTimeout(() => {
      setCurrentScreen(ScreenTypes.LandingPageScreen)
    }, 1000)
  }, [setCurrentScreen])
  // localStorage.removeItem('progress')
  // localStorage.removeItem('payed')
  // localStorage.removeItem('currTime');
  // localStorage.removeItem('payed');
  const screenComponents = {
    [ScreenTypes.SplashScreen]: <SplashScreen />,
    [ScreenTypes.QuizTopicsScreen]: <QuizTopicsScreen />,
    [ScreenTypes.QuizDetailsScreen]: <QuizDetailsScreen />,
    [ScreenTypes.QuestionScreen]: <QuestionScreen />,
    [ScreenTypes.RecommendationScreen]: <RecommendationScreen/>,
    [ScreenTypes.LandingPageScreen]: <LandingPageScreen />,
    [ScreenTypes.RecommendationScreen1]:<RecommendationScreen1 />,
    [ScreenTypes.RecommendationScreen2]:<RecommendationScreen2/>,
    [ScreenTypes.RecommendationScreen3]:<RecommendationScreen3/>,
    [ScreenTypes.RecommendationScreen4]:<RecommendationScreen4 />,
    [ScreenTypes.RecommendationScreen5]:<RecommendationScreen5/>,
    [ScreenTypes.RecommendationScreen6]:<RecommendationScreen6/>,
    [ScreenTypes.PaymentFormScreen]:<CheckoutForm/>,
    [ScreenTypes.StripeContainerScreen]:<StripeContainerScreen/>,
    [ScreenTypes.FixPoorDietComponent]:<FixPoorDietComponent/>,
    [ScreenTypes.FixPoorDietComponent2]:<FixPoorDietComponent2/>,
    [ScreenTypes.FixPoorDietComponent3]:<FixPoorDietComponent3/>,
    [ScreenTypes.FixPoorDietComponent4]:<FixPoorDietComponent4/>,
    [ScreenTypes.FixPoorDietComponent5]:<FixPoorDietComponent5/>,

    [ScreenTypes.FixProteinIntakeComponent]:<FixProteinIntakeComponent/>,
    [ScreenTypes.FixBodyComponent]:<FixBodyComponent/>,
    [ScreenTypes.FixBadEatingPatternComponent]:<FixBadEatingPatternComponent/>,
    [ScreenTypes.FixStressComponent]:<FixStressComponent/>,
    [ScreenTypes.GetMedicalTreatmentComponent]:<GetMedicalTreatmentComponent/>,
    [ScreenTypes.PrivacyPolicy]:<PrivacyPolicy/>,
    [ScreenTypes.HowToUseScreen]:<HowToUseScreen/>,
    [ScreenTypes.TermsOfUseScreen]:<TermsOfUse/>,
    [ScreenTypes.ThankYouPage]:<ThankYouPage/>,

  }

  const ComponentToRender = screenComponents[currentScreen] || <SplashScreen />

  return <div>
    {ComponentToRender}
    </div>
}

export default Main


