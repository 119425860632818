import { Dispatch, SetStateAction, useEffect } from 'react'

interface QuizDetails {
  totalTime: number
}

const useTimer = (
  timer: number,
  quizDetails: QuizDetails,
  setEndTime: (time: number) => void,
  setTimer: Dispatch<SetStateAction<number>>,
) => {
  useEffect(() => {
    if (timer <= 0) {
      const timeTaken = quizDetails.totalTime
      setEndTime(timeTaken)
      setTimer(0)
    }
  }, [timer, quizDetails.totalTime, setEndTime, setTimer])

  useEffect(() => {
    let countTimer: NodeJS.Timeout;
    if (timer > 0){
      countTimer = setTimeout(() => {
        setTimer((prevTimer) => prevTimer - 1)
      }, 1000)
    }
    return () => {
      if (countTimer) {
        clearTimeout(countTimer);
      }
    };
  }, [timer, setTimer])
}

export default useTimer
